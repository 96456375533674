<template>
  <div class="flex flex-column flex-justify-between shareMain">
    <el-card>
      <div class="flex flex-align-center font-18" style="color:#000000">
        <div>推手/员工</div>
        <div class="font-18 color-909399">
          <i class="el-icon-close"></i>
        </div>
        <div>小店分享员</div>
      </div>
      <div class="font-14 color-606266 line-font-1_5 margin-top-10">
        <div>
          1.邀请商城的推手或店员成为视频号的分享员，为视频号直播推广引流，助力视频号销售；
        </div>
        <div>
          2.客户通过分享员推广的直播间链接进入直播间，或分享的小店商品链接，成功购买商品后，订单分享员可获取相应奖励；
          <el-link href="https://jusnn6k8al.feishu.cn/docx/ZnrhdLv6goqCWoxRUP7clIatn8d"
          :underline="false" target="_blank" class="margin-left-10" style="color:#409eff;margin-top:-2px">查看分享奖励结算说明</el-link>
        </div>
        <div>
          3.每个小店可绑定20000个分享员
        </div>
        <div>
          <el-link href="https://jusnn6k8al.feishu.cn/docx/Du0HdxCPCo06lTx3YREcTCMVnqf?from=from_copylink"
          :underline="false" target="_blank" style="color:#409eff;margin-top:-2px">查看分享员使用说明</el-link>
        </div>
      </div>
    </el-card>
    <div class="flex-1 shareBody" style="">
      <not-bind :type="0" @complete="(e)=>{IsBind = e}" v-if="!IsBind&&!total"></not-bind>
      <el-card class="margin-top-10">
        <div class="flex flex-align-center flex-wrap gap-10">
          <div class="flex flex-align-center">
            <div class="keyLabel">关键字：</div>
            <el-input v-model="keywords" style="width:200px" placeholder="微信昵称"></el-input>
          </div>
          <div class="flex flex-align-center">
            <div class="keyLabel">绑定状态：</div>
            <el-select v-model="state" style="width:200px">
              <el-option v-for="v in stateList" :key="v.value" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <el-button type="primary" @click="handleCurrentChange(1)">查询</el-button>
          <el-button type="primary" @click="refresh">刷新</el-button>
          <el-button type="text" @click="InviteShow=true,getinfo()">邀请设置</el-button>
        </div>
        <el-table :data="tablelist" v-loading="tableloading" class="margin-top-10">
          <el-table-column prop="" label="分享员" min-width="150">
            <template slot-scope="scope">
              <div class="flex flex-align-center">
                <img :src="scope.row.HeadUrl||defaultHeader" style="width:40px;height:40px;border-radus:2px;margin-right:10px" alt="">
                <div style="flex:1;width:0">
                  <div class="ellipsis" :style="{'cursor':scope.row.MemberId?'pointer':'','color':scope.row.MemberId?'#409eff':''}" @click="toDetail(scope.row)">
                    {{scope.row.Nickname}} <span v-if="scope.row.Name">({{scope.row.Name}})</span>
                  </div>
                  <div>
                    {{scope.row.Phone}}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="身份">
            <template slot="header" slot-scope="scope">
              <div class="flex flex-align-center">
                <div>身份</div>
                <el-popover placement="top-start" trigger="hover" width="200"
                  content="分享员在商城内的身份：推手、员工、普通客户">
                  <i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
                </el-popover>
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                {{scope.row.Status||'--'}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="绑定状态">
            <template slot="header" slot-scope="scope">
              <div class="flex flex-align-center">
                <div>绑定状态</div>
                <el-popover placement="top-start" trigger="hover" width="200"
                  content="分享员在商城内是否成功绑定推手或员工">
                  <i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
                </el-popover>
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.BindState!=2">
                  {{scope.row.BindStateValue}}
                </div>
                <div class="flex flex-align-center color-f56c6c" v-if="scope.row.BindState==2">
                  <div>绑定失败</div>
                  <el-popover placement="top-start" trigger="hover" width="200"
                    content="分享员在商城内非推手、非员工，绑定失败；用户成为推手或员工后，点击页面刷新按钮，将自动更新绑定状态">
                    <i class="el-icon-warning-outline margin-left-5" slot="reference"></i>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ShopName" label="归属小店">
            <template slot-scope="scope">
              <div style="white-space:nowrap">{{scope.row.ShopName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="LastInviteTime" label="最近一次邀请时间" width="180">
            <template slot-scope="scope">
              {{scope.row.LastInviteTime||'--'}}
            </template>
          </el-table-column>
          <el-table-column prop="" label="企店内绑定成功时间" width="180">
            <template slot-scope="scope">
              <div>
                {{scope.row.BindState==1?(scope.row.BindTime||'--'):'--'}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="120">
            <template slot-scope="scope">
              <div>
                <el-button type="text" v-if="scope.row.BindState==1" @click="unbind(scope.row)">解绑分享员</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="margin-top-10" style="text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-card>
    </div>

    <el-dialog :visible.sync="InviteShow" title="分享员邀请设置" width="600px" @closed="isOpenInvite=result.isOpenInvite">
      <div class="flex">
        <div class="font-14color-606266" style="width:100px;white-space:nowrap">商城内邀请：</div>
        <div>
          <el-radio-group v-model="isOpenInvite">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
          <div class="font-14 color-999999 line-font-1_5 margin-top-10">
            <div>
              开启后，推手或员工进入商城，可自行绑定成为视频号小店分享员
            </div>
            <div>
              关闭后，仅可由商家在视频号小店后台手动邀请分享员
            </div>
            <div>
              <el-popover placement="bottom-start" trigger="click">
                <div class="flex gap-10">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/videoSharer2.png" style="width:180px" alt="">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/videoSharer1.png" style="width:180px" alt="">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/videoSharer3.png" style="width:180px" alt="">
                </div>
                <div slot="reference" style="display:inline-block">
                  <el-button type="text">查看示例</el-button>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
      <div class="margin-top-30" style="text-align:right">
        <el-button @click="InviteShow=false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import notBind from "../components/notBind"
import config from "@/config"
import {
  channelShopchannelShopSharerList,
  channelShopchannelShopSharerUnBind,
  channelShopchannelShopSharerInviteInfo,
  channelShopchannelShopSharerInviteInfoSave,
  channelShopchannelShopSharerSync
} from "@/api/goods"
export default {
  components:{
    notBind
  },
  data () {
    return {
      IsBind:false,
      keywords:'',
      state:null,
      stateList:[
        {
          value:null,
          label:'全部'
        },{
          value:1,
          label:'绑定成功'
        },{
          value:0,
          label:'邀请中'
        },{
          value:2,
          label:'绑定失败'
        },{
          value:3,
          label:'已解绑'
        },
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pageSize:20,
      total:0,
      defaultHeader:config.DEFAULT_HEADER,
      InviteShow:false,
      isOpenInvite:false,
      result:{},
    }
  },
  mounted () {
    this.getinfo()
    this.gettablelist()
  },
  methods: {
    toDetail(row){
      if(!row.MemberId) return
      this.$router.push({
        path:'/Customer/CustomerDetail',
        query:{
          Id:row.MemberId
        }
      })
    },
    async refresh(){
      try{
        let res = await channelShopchannelShopSharerSync()
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        //
      }
    },
    async getinfo(){
      try{
        let res = await channelShopchannelShopSharerInviteInfo()
        if(res.IsSuccess){
          this.isOpenInvite = res.Result.IsOpenChannelShopSharerInvite
        }
      }finally{
        //
      }
    },
    async confirm(){
      try{
        let res = await channelShopchannelShopSharerInviteInfoSave({
          IsOpenChannelShopSharerInvite:this.isOpenInvite
        })
        if(res.IsSuccess){
          this.InviteShow = false
          this.$message.success('操作成功')
          this.getinfo()
        }
      }finally{
        //
      }
    },
    async unbind(row){
      try{
        this.tableloading = true
        let res = await channelShopchannelShopSharerUnBind({
          Id:row.Id
        })
        if(res.IsSuccess){
          this.$message.success('解绑成功')
          this.gettablelist()
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pageSize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await channelShopchannelShopSharerList({
          Keywords:this.keywords,
          BindState:this.state,
          Skip:(this.currentPage-1)*this.pageSize,
          Take:this.pageSize
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }
  }
}
</script>

<style lang='less' scoped>
.shareMain{
  position: relative;
  top: 10px;
  bottom: 10px;
  left:10px;
  right:10px;
  .shareBody{
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 500px;
    ::v-deep .carpeting{
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
}
</style>